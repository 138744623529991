import {
  Container,
  Navbar
} from 'react-bootstrap';

const NftopNavbar = () => {
  return (
    <Container fluid>
      <Navbar>
        <Container fluid>
          <Navbar.Brand href="/"> 🎩 NFTop </Navbar.Brand>
        </Container>
      </Navbar>
    </Container>
  );
}

export default NftopNavbar;
