import React, { useState, useEffect, useCallback, useRef } from "react";

import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Figure,
} from "react-bootstrap";
import { toPng } from "html-to-image";
import InfiniteScroll from "react-infinite-scroll-component";
import Discord from "./Discord";
import axios from "axios";

// import { ReactPhotoCollage } from "react-photo-collage";
// import NftopCollage from './NftopCollage.js';

const CONTROLLER = require("../controllers/nftAssetsController.js");

const Nftop = () => {
  const [ethAddr, setEthAddr] = useState("");
  const [nftList, setNftList] = useState([]);
  const [hasMoreNFTs, setHasMoreNFTs] = useState();
  const [trending, setTrending] = useState([]);
  const [selectedNFTs, setSelectedNFTs] = useState({});
  const [nftOrder, setNftOrder] = useState([]);
  const [numSelected, setNumSelected] = useState(0);

  useEffect(() => {
    // set placeholder trending NFTs for now
    setTrendingPlaceholder();
  }, []);

  const ref = useRef(null);

  const onDownload = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "my-nftop.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  const setTrendingPlaceholder = () => {
    const temp = [
      { img: "/Group 17.png", name: "Beyonce's curation" },
      { img: "/Group 18.png", name: "Tame Impala’s curation" },
      { img: "/Group 19.png", name: "Punk 6529 curation" },
      { img: "/Group 20.png", name: "Roast BEETH curation" },
    ];
    setTrending(temp);
  };

  const handleFetchMore = () => {
    console.log("here");

    const os_options = {
      method: "GET",
      url: "https://api.opensea.io/api/v1/assets",
      params: {
        owner: ethAddr,
        order_direction: "desc",
        offset: nftList.length,
        limit: "9",
      },
    };

    axios
      .request(os_options)
      .then(function (response) {
        const assets = response.data.assets;
        const nftNextImageList = [];

        for (let index in assets) {
          let asset = assets[index];

          nftNextImageList.push({
            image: asset.image_original_url,
            imageUrl: asset.image_url,
            collectionName: asset.collection.name,
            collectionId: asset.asset_contract.address,
            tokenId: asset.token_id,
          });
        }
        setNftList(nftList.concat(nftNextImageList));
        setHasMoreNFTs(nftNextImageList.length === 9);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  async function setupNFTs() {
    if (ethAddr.trim().length == 0) {
      alert("Enter your eth address to view and select your NFTs.");
      return;
    }

    const os_options = {
      method: "GET",
      url: "https://api.opensea.io/api/v1/assets",
      params: {
        owner: ethAddr,
        order_direction: "desc",
        offset: "0",
        limit: "9",
      },
    };

    axios
      .request(os_options)
      .then(function (response) {
        console.log(response.data);
        const assets = response.data.assets;
        const nftImageList = [];

        for (let index in assets) {
          let asset = assets[index];

          nftImageList.push({
            image: asset.image_original_url,
            imageUrl: asset.image_thumbnail_url,
            collectionName: asset.collection.name,
            collectionId: asset.asset_contract.address,
            tokenId: asset.token_id,
          });
        }

        setNftList(nftImageList);
        setHasMoreNFTs(nftImageList.length === 9);
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  const selectNft = (asset) => {
    var copy = Object.assign({}, selectedNFTs);
    var orderCopy = nftOrder.slice();
    if (copy[asset.tokenId] === undefined) {
      if (numSelected >= 4) {
        alert("You can only select 4 NFTs.");
        return;
      }
      copy[asset.tokenId] = asset;
      setNumSelected(numSelected + 1);
      orderCopy.push(asset.tokenId);
    } else {
      delete copy[asset.tokenId];
      setNumSelected(numSelected - 1);
      for (var i = 0; i < orderCopy.length; i++) {
        if (orderCopy[i] === asset.tokenId) {
          orderCopy.splice(i, 1);
          break;
        }
      }
    }
    setSelectedNFTs(copy);
    setNftOrder(orderCopy);
  };

  // const getAssets = () => {
  //   if(ethAddr.trim().length == 0) {
  //     alert("Enter your eth address to view and select your NFTs.");
  //     return;
  //   }
  //   const callback = (res) => {setAssets(res.data.assets)};
  //   const callbackOnError = (error) => {alert(error.message);};
  //   CONTROLLER.getAssets(ethAddr, callback, callbackOnError);
  // }

  return (
    <Container style={{ marginTop: "25px" }}>
      <Row>
        <Col style={{ textAlign: "center" }}>
          <h1
            style={{ color: "#5DA9DD", fontWeight: "bold", fontSize: "400%" }}
          >
            {" "}
            Showcase your top NFTs{" "}
          </h1>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={2}></Col>

        {/* Eth address + NFT select */}
        <Col md={8} style={{ marginBottom: "20px" }}>
          <Row>
            <Col>
              <InputGroup>
                <Form.Control
                  placeholder="0x..."
                  onChange={(e) => {
                    setEthAddr(e.target.value);
                  }}
                />
                <Button
                  variant="light"
                  onClick={setupNFTs}
                  style={{
                    border: "1px solid #808B96",
                    padding: ".375rem .75rem",
                    backgroundColor: "#5DA9DD",
                  }}
                >
                  Search
                </Button>
              </InputGroup>
              <div style={{ height: "10px" }}></div>
              <Form.Text muted>
                Enter your eth address and then select your NFTs to create your
                NFTop. We only support OpenSea NFTs for now.
              </Form.Text>
            </Col>
          </Row>
          <br />
          {nftList.length == 0 ? (
            <Row style={{ marginTop: "50px" }}>
              <Col style={{ textAlign: "center" }}>
                <h1 style={{ fontSize: "1000%" }}> 🎩 </h1>
                <p> Your NFTs will appear here </p>
              </Col>
            </Row>
          ) : (
            <div>
              <Row>
                <Col>
                  <p> Select your NFTs to create your NFTop (limit: 4) </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    style={{
                      padding: "20px",
                      backgroundColor: "#F2F2F2",
                      borderRadius: "25px",
                    }}
                  >
                    <InfiniteScroll
                      dataLength={nftList.length} //This is important field to render the next data
                      next={handleFetchMore}
                      hasMore={hasMoreNFTs}
                      loader={
                        <p style={{ textAlign: "center" }}>
                          <b>Loading more NFTs...</b>
                        </p>
                      }
                      height={250}
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b>Yay! You have seen it all</b>
                        </p>
                      }
                    >
                      {nftList.map((nft) => {
                        return (
                          <Figure
                            key={nft.collectionId + nft.tokenId}
                            onClick={() => {
                              selectNft(nft);
                            }}
                            style={{
                              marginLeft: "5%",
                              marginRight: "1%",
                              marginBottom: "3%",
                            }}
                          >
                            <Figure.Image
                              width={150}
                              height={150}
                              src={nft.image}
                              style={
                                selectedNFTs[nft.tokenId]
                                  ? { border: "5px solid #555" }
                                  : {}
                              }
                            />
                          </Figure>
                        );
                      })}
                    </InfiniteScroll>
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  {" "}
                  <p>
                    {" "}
                    Your NFTop (fits Twitter and Discord banner dimensions 📏){" "}
                  </p>{" "}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    style={{
                      position: "relative",
                      height: "271px",
                      width: "813px",
                      padding: "30px",
                      overflowX: "scroll",
                      overflowY: "hidden",
                      whiteSpace: "nowrap",
                      background: "linear-gradient(lightblue ,darkblue)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "0px",
                    }}
                    ref={ref}
                  >
                    {nftOrder.map((id) => {
                      return (
                        <img
                          key={id + "-selected"}
                          onClick={() => {
                            selectNft(selectedNFTs[id]);
                          }}
                          style={{
                            marginLeft: "2%",
                            marginRight: "2%",
                            maxWidth: "150px",
                            height: "auto",
                          }}
                          src={selectedNFTs[id].imageUrl}
                          className="art"
                        />
                      );
                    })}

                    <p
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        padding: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      🎩 NFTop
                    </p>
                    <p
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        padding: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {ethAddr}
                    </p>
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Button
                    onClick={onDownload}
                    style={{ backgroundColor: "royalblue" }}
                  >
                    Download
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Col>

        <Col xs={2}></Col>
        {/* Trending NFTops */}
        {/*
        <Col md={4}>
          <Row style={{backgroundColor: "#F2F2F2", borderRadius: "25px"}}>
            <Col>
              <Row>
                <Col>
                  <h5 style={{marginTop: "15px"}}> 📈 Trending NFTops </h5>
                </Col>
              </Row>
              <br/>
              <Row>
                {trending.map((nft, index) => {
                  return (
                    <Col xs={12} style={{marginBottom: "20px", textAlign: "center"}}>
                      <img src={nft.img} style={{width: "80%", float: "center"}}/>
                      <div> {nft.name} </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Col>
        */}
      </Row>
      <br />
      <br />
      <Discord />
    </Container>
  );
};

export default Nftop;
